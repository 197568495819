import { useRef, useState } from "react";

import { InvoiceContainer } from "features/invoice";

import { SkeletonGroup } from "components/SkeletonGroup/SkeletonGroup";

import { classNames } from "lib/classNames";
import {
  BroadcastMapper,
  useBroadcastChannel,
} from "lib/hooks/useBroadcastChannel";
import { useScroll } from "lib/hooks/useScroll";
import { useGetAllInvoicesQuery } from "lib/slices/invoiceServiceSlice";
import { Invoice } from "lib/types";

import styles from "../PageAllInvoices.module.scss";
import { Card } from "components/Card";
import { Text } from "components/Text";

export function AllInvoicesTab({
  broadcastPostMessageHandler,
}: {
  broadcastPostMessageHandler: (
    eventData: BroadcastMapper["update_invoice"]["payload"],
  ) => void;
}) {
  const invoicesWrapperRef = useRef<HTMLDivElement>(null);
  const [page, setPage] = useState(0);

  const { data, isFetching } = useGetAllInvoicesQuery(page, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
    refetchOnReconnect: true,
  });

  useScroll<Invoice[], HTMLDivElement>(
    invoicesWrapperRef,
    page,
    isFetching,
    data,
    () => setPage(page + 1),
  );

  useBroadcastChannel("update_invoice", (eventData) =>
    broadcastPostMessageHandler(eventData),
  );

  return (
    <section>
      <div
        className={classNames({
          [styles.invoices]: true,
        })}
        ref={invoicesWrapperRef}
      >
        {data?.data.map((invoice, index) => (
          <InvoiceContainer
            key={index}
            paymentStatus={invoice.paymentStatus}
            stage={invoice.stage}
            invoiceId={invoice.id}
            displayId={invoice.invoiceNo}
            date={invoice.date}
            amount={invoice.totalAmount}
            currency={invoice.currency}
          />
        ))}

        {/* prettier-ignore */}
        {(!data ||
          (data && data.data.length < data.totalItems && page !== 0)) && (
          <SkeletonGroup
            numberOfRowsWithSkeletons={5}
            options={[
              {
                parentJustifyContent: "space-between",
                items: [
                  {
                    width: "147px",
                    height: "18px",
                    borderRadius: "10px",
                  },
                  {
                    gap: "16px",
                    items: [
                      {
                        width: "175px",
                        height: "18px",
                        borderRadius: "10px",
                      },
                      {
                        width: "116px",
                        height: "18px",
                        borderRadius: "10px",
                      },
                    ],
                  },
                ],
              },
            ]}
          />
        )}

        {/* No invoices */}
        {data?.data.length === 0 && <Card><Text as="h3" style="body">No invoices yet!</Text></Card>}
      </div>
    </section>
  );
}
