import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { prepareHeaders } from "lib/prepareHeaders";
import { Invoice } from "lib/types";

interface Response<T> {
  data: T;
  totalItems: number;
}

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_INVOICE_URL,
  credentials: "include",
  prepareHeaders,
});

// When switching before tabs, newly created invoices may come
// So that's why this function will prepend them to the current cache entry
// Fetching of invoices on scroll still can happen
// by appending the invoices to the end
const mergeLists = (currentList: Invoice[], newList: Invoice[]) => {
  const findMostRecentElements: Invoice[] = [];

  for (const newEl of newList) {
    if (!currentList.find(el => el.id === newEl.id)) {
      findMostRecentElements.push(newEl);
    }
  }

  currentList.unshift(...findMostRecentElements);

  currentList.push(
    ...newList.filter(
      (newEl) =>
        !currentList.find((cacheEntry) => newEl.id === cacheEntry.id) &&
        !findMostRecentElements.includes(newEl),
    ),
  );
};

export const invoiceServiceSlice = createApi({
  reducerPath: "invoiceServiceApi",
  tagTypes: ["Invoice"],
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getInvoice: builder.query<Invoice, string>({
      query: (id) => `/invoices/${id}`,
      providesTags: (result, error, id) => [{ type: "Invoice", id }],
    }),
    getAllInvoices: builder.query<
      {
        data: Invoice[];
        totalItems: number;
      },
      string | number
    >({
      query: (page) =>
        `/invoices?page=${page}&limit=20&sort=createdAt&order=desc`,
      merge: (currentCache, newItems) => {
        if (!currentCache) {
          currentCache = {
            data: [],
            totalItems: 0,
          };
        }

        if (newItems.data.length > 0) {
          mergeLists(currentCache.data, newItems.data);

          currentCache.totalItems = newItems.totalItems;
        }
      },
      serializeQueryArgs: ({ endpointName }: { endpointName: string }) => {
        return endpointName;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      transformResponse: (res: Response<Invoice[]>) => {
        return {
          data: res.data,
          totalItems: res.totalItems,
        };
      },
    }),
    getPaidInvoices: builder.query<
      {
        data: Invoice[];
        totalItems: number;
      },
      string | number
    >({
      query: (page) =>
        `/invoices?paymentStatus=paid&page=${page}&limit=20&sort=createdAt&order=desc`,
      merge: (currentCache, newItems, { arg: page }) => {
        if (page === 0) {
          return {
            data: newItems.data,
            totalItems: newItems.totalItems,
          };
        }

        if (!currentCache) {
          currentCache = {
            data: [],
            totalItems: 0,
          };
        }

        if (newItems.data.length > 0) {
          mergeLists(currentCache.data, newItems.data);

          currentCache.totalItems = newItems.totalItems;
        }
      },
      serializeQueryArgs: ({ endpointName }: { endpointName: string }) => {
        return endpointName;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      transformResponse: (res: Response<Invoice[]>) => {
        return {
          data: res.data,
          totalItems: res.totalItems,
        };
      },
    }),
    getUnpaidInvoices: builder.query<
      {
        data: Invoice[];
        totalItems: number;
      },
      string | number
    >({
      query: (page) =>
        `/invoices?paymentStatus=notPaid&page=${page}&limit=20&sort=createdAt&order=desc`,
      merge: (currentCache, newItems, { arg: page }) => {
        if (page === 0) {
          return {
            data: newItems.data,
            totalItems: newItems.totalItems,
          };
        }

        if (!currentCache) {
          currentCache = {
            data: [],
            totalItems: 0,
          };
        }

        if (newItems.data.length > 0) {
          mergeLists(currentCache.data, newItems.data);

          currentCache.totalItems = newItems.totalItems;
        }
      },
      serializeQueryArgs: ({ endpointName }: { endpointName: string }) => {
        return endpointName;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      transformResponse: (res: Response<Invoice[]>) => {
        return {
          data: res.data,
          totalItems: res.totalItems,
        };
      },
    }),
    resendEmail: builder.mutation<boolean, string>({
      query: (id) => ({
        url: `/invoices/${id}/resend-email`,
        method: "POST",
      }),
      transformResponse: (res: boolean) => {
        return res;
      },
    }),
  }),
});

export const {
  useGetInvoiceQuery,
  useGetAllInvoicesQuery,
  useGetPaidInvoicesQuery,
  useGetUnpaidInvoicesQuery,
  useResendEmailMutation,
} = invoiceServiceSlice;
