import { createElement, CSSProperties } from "react";

import { icons } from "assets/icons";

import styles from "./Icon.module.scss";

export type Icons = keyof typeof icons;

interface CustomStyles extends CSSProperties {
  "--icon-size": string;
  "--icon-intent": string;
}

type Props = {
  name: Icons;
  intent?: `--${string}` | string;
  size?: number;
  [key: string]: unknown;
};

export function Icon({
  name,
  intent = "--static-content-emphasis",
  size = 24,
  ...props
}: Props) {
  return createElement(icons[name], {
    className: styles.icon,
    style: {
      "--icon-size": `${size}px`,
      "--icon-intent": `var(${intent})`,
    } as CustomStyles,
    ...props,
  });
}
