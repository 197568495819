import { useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

import { Button } from "components/Button";

import { copyToClipboard } from "lib/helpers";
import { useOutsideClick } from "lib/hooks/useOutsideClick";

import styles from "./FixedTooltip.module.scss";

type Props = {
  children: React.ReactNode;
  tooltipContent: React.ReactNode;
  valueToCopy: string;
  direction?: "left" | "right";
  timerOffset?: number;
};

/**
 * @description The current Tooltip.tsx is not working properly with the accordion component
 * due to overflow: hidden set on the accordion container!
 * This component was created to fix the issue with the accordion component
 * and currently supports only left and right directions!
 */

export function FixedTooltip({
  children,
  tooltipContent,
  valueToCopy,
  direction = "right",
  timerOffset = 5,
}: Props) {
  // Refs
  const elementToOpenTooltipRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const offset = useRef(5);
  const timer = useRef<NodeJS.Timeout | null>(null);
  const timerOffsetRef = useRef(timerOffset * 1000);

  const [showTooltip, setShowTooltip] = useState(false);
  const [dynamicStyles, setDynamicStyles] = useState({});

  // Hide when clicked outside
  useOutsideClick(elementToOpenTooltipRef, () => {
    setShowTooltip(false);
    setDynamicStyles({});
  });

  // Initiate a timer
  useEffect(() => {
    if (showTooltip) {
      timer.current = setTimeout(() => {
        setShowTooltip(false);
        setDynamicStyles({});
      }, timerOffsetRef.current);
    }

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, [showTooltip]);

  // Compute direction offset
  const computeDirectionOffset = useCallback(
    (elementToOpenTooltipDims: DOMRect) => {
      if (elementToOpenTooltipDims) {
        const { top, left, right, width } = elementToOpenTooltipDims;
        const scrollY = window.scrollY;
        const scrollX = window.scrollX;

        switch (direction) {
        case "right":
          return {
            top: top + scrollY,
            left: right + scrollX + offset.current,
            padding: offset.current,
          };
        case "left":
          return {
            top: top + scrollY,
            left: left + scrollX - width - offset.current * 3,
            padding: offset.current,
          };
        }
      }
    },
    [direction],
  );

  // Compute based on browsers edges
  const computeBasedOnBrowserEdges = useCallback(
    <T extends HTMLElement>(
      styles: Record<string, string>,
      tooltipRef: T | null,
    ) => {
      if (tooltipRef && elementToOpenTooltipRef.current) {
        // prettier-ignore
        const elementToOpenTooltipDims = elementToOpenTooltipRef.current?.getBoundingClientRect();
        const tooltipDims = tooltipRef.getBoundingClientRect();

        if (direction === "left") {
          styles = {
            ...styles,
            left: +styles.left - tooltipDims.width / 1.7 + "px",
          };
        }

        if (elementToOpenTooltipDims && tooltipDims) {
          // If there is right overflow
          if (
            tooltipDims.left + tooltipDims.width + offset.current >
            window.innerWidth
          ) {
            styles = {
              ...styles,
              left:
                elementToOpenTooltipDims!.left +
                window.scrollX -
                tooltipDims.width -
                offset.current +
                "px",
            };
          }

          // If there is a left overflow
          if (tooltipDims.left - tooltipDims.width < 0) {
            styles = {
              ...styles,
              left:
                elementToOpenTooltipDims.left +
                window.scrollX +
                elementToOpenTooltipDims.width +
                offset.current +
                "px",
            };
          }
        }
      }

      return styles;
    },
    [direction],
  );

  // Toggle tooltip visibility and compute direction styles
  const handleToggleTooltip = useCallback(() => {
    setShowTooltip(true);

    if (!showTooltip) {
      copyToClipboard(valueToCopy);
      
      if (elementToOpenTooltipRef.current) {
        const dims = elementToOpenTooltipRef.current?.getBoundingClientRect();

        setDynamicStyles({
          ...computeDirectionOffset(dims),
        });
      }
    }
  }, [showTooltip, valueToCopy, computeDirectionOffset]);

  // Effect to compute styles after tooltip appears
  useEffect(() => {
    let changeViewportCallback = () => {};

    if (showTooltip && tooltipRef.current && elementToOpenTooltipRef.current) {
      setDynamicStyles((prev) => {
        return {
          ...prev,
          ...computeBasedOnBrowserEdges(prev, tooltipRef.current),
        };
      });

      changeViewportCallback = () => {
        const elementToOpenTooltipDims =
          elementToOpenTooltipRef.current?.getBoundingClientRect();
        const tooltipDims = tooltipRef.current?.getBoundingClientRect();

        if (
          tooltipRef.current &&
          elementToOpenTooltipRef.current &&
          elementToOpenTooltipDims &&
          tooltipDims
        ) {
          if (direction === "right") {
            tooltipRef.current.style.top =
              elementToOpenTooltipDims.top + window.scrollY + "px";

            if (
              tooltipDims.right + tooltipDims.width + offset.current <
              window.innerWidth
            ) {
              tooltipRef.current.style.left =
                elementToOpenTooltipDims.right +
                window.scrollX +
                offset.current +
                "px";
            }
          }

          if (direction === "left") {
            tooltipRef.current.style.top =
              elementToOpenTooltipDims.top + window.scrollY + "px";

            if (
              tooltipDims.left + tooltipDims.width + offset.current <
              tooltipDims.width
            ) {
              tooltipRef.current.style.left =
                elementToOpenTooltipDims.left -
                window.scrollX -
                tooltipDims.width -
                offset.current +
                "px";
            }
          }
        }
      };

      window.addEventListener("resize", changeViewportCallback);
      window.addEventListener("scroll", changeViewportCallback);
    }

    return () => {
      window.removeEventListener("resize", changeViewportCallback);
      window.removeEventListener("scroll", changeViewportCallback);
    };
  }, [showTooltip, direction, computeBasedOnBrowserEdges]);

  return (
    <div ref={elementToOpenTooltipRef}>
      <Button intent="clear" onClick={handleToggleTooltip}>
        {children}
      </Button>

      {showTooltip &&
        createPortal(
          <div
            ref={tooltipRef}
            className={styles.tooltipContent}
            style={dynamicStyles}
          >
            {tooltipContent}
          </div>,
          document.getElementById("tooltips")!,
        )}
    </div>
  );
}
