import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";

import { Button } from "components/Button";
import { Card } from "components/Card";

import { useHead } from "lib/hooks/useHead";
import { invoiceServiceSlice } from "lib/slices/invoiceServiceSlice";

import styles from "./PageHome.module.scss";

export function PageHome() {
  const { t } = useTranslation();
  const params = useParams();
  const dispatch = useDispatch();

  useHead(t("pages.home.title"));

  useEffect(() => {
    dispatch(
      invoiceServiceSlice.util.invalidateTags([
        {
          type: "Invoice",
          id: params.id,
        },
      ]),
    );
  }, [dispatch, params.id]);

  return (
    <div className={styles.content}>

      <Link to="/all-invoices">
        <Card>
          <Button
            text={t("pages.home.allInvoices")}
            icon="chevronRight"
            intent="clear"
            fill
            textLeft
          />
        </Card>
      </Link>
      <Link to="/faq">
        <Card>
          <Button
            text={t("pages.home.faq")}
            icon="chevronRight"
            intent="clear"
            fill
            textLeft
          />
        </Card>
      </Link>
    </div>
  );
}
