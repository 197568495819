import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Button } from "components/Button";
import { Card } from "components/Card";
import { PageHeader } from "components/PageHeader";

import styles from "./PageFAQ.module.scss";

export function PageFAQ() {
  const { t } = useTranslation();

  const FAQs = [
    {
      title: t("pages.faq.refunds"),
      href: "/faq/refunds",
    },
    {
      title: t("pages.faq.declinedPayments"),
      href: "/faq/declined-payments",
    },
    {
      title: t("pages.faq.frauds"),
      href: "/faq/frauds",
    },
    {
      title: t("pages.faq.accountPrivacy"),
      href: "/faq/account-privacy",
    },
  ];

  return (
    <div className={styles.content}>
      <PageHeader title={t("pages.faq.title")} />

      <section className={styles.linksContainer}>
        {FAQs.map((faq, index) => (
          <Link key={index} to={faq.href}>
            <Card>
              <Button
                text={faq.title}
                icon="chevronRight"
                intent="clear"
                fill
                textLeft
              />
            </Card>
          </Link>
        ))}
      </section>
    </div>
  );
}
