import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Card } from "components/Card";
import { Text } from "components/Text";

import { IRootState } from "lib/store";

import styles from "./PageNotFound.module.scss";

type Props = {
  titleTranslationKey: string;
  messageTranslationKey: string;
};

export function PageNotFound({
  titleTranslationKey,
  messageTranslationKey,
}: Props) {
  const { t } = useTranslation();
  const user = useSelector((state: IRootState) => state.user.user);

  const layoutPageStyles = useMemo(() => {
    if (!user) {
      return {
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      };
    }
  }, [user]);

  return (
    <div className="content" style={layoutPageStyles}>
      <Card className={styles.pageNotFound}>
        <div>
          <Text as="h1" style="h4">
            {t(`pages.notFound.${titleTranslationKey}`)}
          </Text>
          <br />
          <Text as="p" style="body-small--reset-line-height">
            {t(`pages.notFound.${messageTranslationKey}`)}
          </Text>   
        </div>

        <div className={styles.status}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="179"
            height="258"
            viewBox="0 0 179 258"
            fill="none"
          >
            <path
              d="M178.617 195.054H145.38V257.047H116.997V195.054H0.106079V173.394L104.3 0.110901H145.38V169.659H178.617V195.054ZM116.997 169.659V26.2527L31.1028 169.659H116.997Z"
              fill="#EBEBEB"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="194"
            height="269"
            viewBox="0 0 194 269"
            fill="none"
          >
            <path
              d="M96.6691 268.65C40.2775 268.65 0.317871 220.848 0.317871 134.206C0.317871 47.9385 40.2775 0.136398 96.6691 0.136398C153.061 0.136398 193.394 47.9385 193.394 134.206C193.394 220.848 153.061 268.65 96.6691 268.65ZM96.6691 242.882C125.052 242.882 163.144 218.607 163.144 134.206C163.144 49.8058 125.052 25.9047 96.6691 25.9047C68.2866 25.9047 30.1942 49.8058 30.1942 134.206C30.1942 218.607 68.2866 242.882 96.6691 242.882Z"
              fill="#EBEBEB"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="179"
            height="258"
            viewBox="0 0 179 258"
            fill="none"
          >
            <path
              d="M178.617 195.054H145.38V257.047H116.997V195.054H0.106079V173.394L104.3 0.110901H145.38V169.659H178.617V195.054ZM116.997 169.659V26.2527L31.1028 169.659H116.997Z"
              fill="#EBEBEB"
            />
          </svg>
        </div>
      </Card>
    </div>
  );
}
