import styles from "./CircularProgress.module.scss";

type Props = {
    color: string;
    spinnerSize: string;
    stroke: string;
};

export function CircularProgress({ color, spinnerSize, stroke }: Props) {
  return (
    <span className={styles.loader} style={{ 
      borderColor: color,
      borderWidth: stroke,
      width: spinnerSize,
      height: spinnerSize
    }}></span>
  );
}