import { Card } from "components/Card";
import styles from "./ErrorMessage.module.scss";
import { Icon } from "components/Icon";

export function ErrorMessage({ children }: { children: React.ReactNode}) {
  return <Card className={styles.errorMessage}>
    <div>
      <Icon name="alertTriangle" size={50} intent="--color-red" />
      
      <div>{children}</div>
    </div>
  </Card>;
}