import { useState } from "react";

import { Button } from "components/Button";
import { Card } from "components/Card";

import { classNames } from "lib/classNames";

import styles from "./CardAccordion.module.scss";

type Props = {
  btn: JSX.Element | JSX.Element[];
  children: JSX.Element | JSX.Element[];
  onChange?: (isOpen: boolean, callback?: () => void) => void;
  onOpen?: () => void;
  disabled?: boolean;
};

export function CardAccordion({
  btn,
  children,
  onChange,
  onOpen,
  disabled,
}: Props) {
  const [isOpen, setIsOpen] = useState(disabled || false);

  const toggleAccordion = () => {
    if(disabled) return;

    setIsOpen(!isOpen);
    onChange?.(!isOpen, onOpen);
  };

  return (
    <Card noPadding>
      <Button onClick={toggleAccordion} intent="clear" fill>
        {btn}
      </Button>

      <div
        className={classNames(styles.accordionContent, {
          [styles.accordionContentShow]: isOpen,
        })}
      >
        {children}
      </div>
    </Card>
  );
}
