import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";

import { InvoiceHero } from "features/invoice";

import { Button } from "components/Button";
import { Card } from "components/Card";
import { SkeletonGroup } from "components/SkeletonGroup/SkeletonGroup";

import { useHead } from "lib/hooks/useHead";
import {
  invoiceServiceSlice,
  useGetInvoiceQuery,
} from "lib/slices/invoiceServiceSlice";

import styles from "../PageHome/PageHome.module.scss";
import { extractReminderFee } from "lib/helpers";

export function PageInvoice() {
  const { t } = useTranslation();
  const params = useParams();
  const dispatch = useDispatch();

  const { data: invoice, isFetching } = useGetInvoiceQuery(params.id as string);

  useHead(t("pages.home.title"));

  useEffect(() => {
    dispatch(
      invoiceServiceSlice.util.invalidateTags([
        {
          type: "Invoice",
          id: params.id,
        },
      ]),
    );
  }, [dispatch, params.id]);

  return (
    <div className={styles.content}>
      {isFetching && !invoice && (
        <Card noPadding>
          <SkeletonGroup
            options={[
              {
                width: "100%",
                height: "300px",
                borderRadius: "16px",
                marginBottom: "16px",
              },
            ]}
          />
        </Card>
      )}

      {invoice && (
        <InvoiceHero
          invoiceTitle={invoice.description}
          invoiceId={invoice.id}
          displayId={invoice.invoiceNo}
          amount={invoice.remainingAmount > 0 ? invoice.remainingAmount : Number(invoice.totalAmount)}
          currency={invoice.currency}
          dueDate={invoice.dueDate}
          invoiceStage={invoice.stage}
          invoicePaymentStatus={invoice.paymentStatus}
          invoiceStatus={invoice.status}
          reminderFee={extractReminderFee(invoice)}
        />
      )}

      <Link to="/all-invoices">
        <Card>
          <Button
            text={t("pages.home.allInvoices")}
            icon="chevronRight"
            intent="clear"
            fill
            textLeft
          />
        </Card>
      </Link>
      <Link to="/faq">
        <Card>
          <Button
            text={t("pages.home.faq")}
            icon="chevronRight"
            intent="clear"
            fill
            textLeft
          />
        </Card>
      </Link>
    </div>
  );
}
