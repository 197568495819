import { useTranslation } from "react-i18next";

import { Card } from "components/Card";
import { Icon } from "components/Icon";

import styles from "./EmptyHistory.module.scss";

export function EmptyHistory() {
  const { t } = useTranslation();

  return (
    <Card>
      <div className={styles.emptyHistoryContainer}>
        <Icon name="alertTriangle" size={50} intent="--static-content-subtle" />
        <p>{t("features.invoice.emptyHistoryMessage")}</p>
      </div>
    </Card>
  );
}
