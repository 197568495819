import { useTranslation } from "react-i18next";

import { Icon } from "components/Icon";
import { Text } from "components/Text";
import { FixedTooltip } from "components/Tooltip/FixedTooltip";

import styles from "./InvoicePaymentCard.module.scss";

export function PaymentBankTransfer({
  bankName,
  paymentReference,
  iban,
}: {
  bankName: string;
  paymentReference: string;
  iban: string;
}) {
  const { t } = useTranslation();

  return (
    <div className={styles.bankTransferWrapper}>
      <div>
        <Text as="p" style="body">
          {t("features.invoice.payment.bankTransferBankName")}
        </Text>
        <Text as="p" style="body">
          {bankName}
        </Text>
      </div>
      <div>
        <Text as="p" style="body">
          {t("features.invoice.payment.bankTransferPaymentReference")}
        </Text>
        <div className={styles.copy}>
          <Text as="p" style="body">
            {paymentReference}
          </Text>

          <FixedTooltip
            tooltipContent={t("common.copied")}
            valueToCopy={paymentReference}
            direction="right"
          >
            <Icon name="copy" size={17} />
          </FixedTooltip>
        </div>
      </div>
      <div>
        <Text as="p" style="body">
          {t("features.invoice.payment.bankTransferIBAN")}
        </Text>
        <div className={styles.copy}>
          <Text as="p" style="body">
            {iban}
          </Text>

          <FixedTooltip
            tooltipContent={t("common.copied")}
            valueToCopy={iban}
            direction="right"
          >
            <Icon name="copy" size={17} />
          </FixedTooltip>
        </div>
      </div>
    </div>
  );
}
