import { InvoicePaymentStatus, InvoiceStage, PillIntent } from "./types";

// Pill stage intent mapper
export const pillIntentStageMapper: Record<InvoiceStage, PillIntent> = {
  invoiced: "info",
  reminded: "notice",
  debtCollection: "danger",
};

// Pill stage intent mapper
export const pillIntentStatusMapper: Record<InvoicePaymentStatus, PillIntent> = {
  partiallyPaid: "warning",
  notPaid: "error",
  paid: "success",
  overPaid: "success",
};

// Pill payment status intent mapper
export const pillIntentStatusCallbackMapper = (
  paymentStatus: InvoicePaymentStatus,
  stage: InvoiceStage,
) => {
  if (paymentStatus === InvoicePaymentStatus["notPaid"]) {
    // INVOICED stage
    if (stage === InvoiceStage["invoiced"]) {
      return "clear";
    }
    // REMINDED stage
    else if (stage === InvoiceStage["reminded"] || stage === InvoiceStage["debtCollection"]) {
      return "error";
    }
  }
  // If payment status is partially paid
  else if (paymentStatus === InvoicePaymentStatus["partiallyPaid"]) {
    return "warning";
  }
  // If payment status is paid
  else if (paymentStatus === InvoicePaymentStatus["paid"] || InvoicePaymentStatus["overpaid"]) {
    return "success";
  }

  return "clear";
};
