import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { prepareHeaders } from "lib/prepareHeaders";
import {
  SwishPaymentRequest,
  SwishStatus,
  SwishStatusResponse,
} from "lib/types";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_PAYMENT_URL,
  credentials: "include",
  prepareHeaders,
});

export const paymentServiceSlice = createApi({
  reducerPath: "paymentServiceApi",
  baseQuery: baseQuery,
  tagTypes: ["Payment"],
  endpoints: (builder) => ({
    initSwishPayment: builder.mutation<SwishStatus, SwishPaymentRequest>({
      query: (body) => ({
        url: "/payments/swish/payment",
        method: "POST",
        body,
      }),
    }),
    getSwishStatus: builder.query<
      SwishStatusResponse,
      { swishId: string; invoiceId: string }
    >({
      query: ({ swishId, invoiceId }) => ({
        url: "/payments/swish/status",
        method: "POST",
        body: { id: swishId, invoiceId },
      }),
    }),
  }),
});

export const { useGetSwishStatusQuery, useInitSwishPaymentMutation } =
  paymentServiceSlice;
