import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { CircularProgress } from "components/Progress/CircularProgress";

import { appStorage } from "lib/localStorage";
import { useGetCustomerQuery } from "lib/slices/customerServiceSlice";
import { setUser } from "lib/slices/userSlice";
import { IRootState } from "lib/store";

import { PageNotFound } from "pages/PageNotFound/PageNotFound";

import styles from "./AuthProvider.module.scss";

type Props = {
  children: JSX.Element | JSX.Element[];
};

export function AuthProvider({ children }: Props) {
  const { t } = useTranslation();
  const params = new URLSearchParams(window.location.search);
  const extractCustomerId = params.get("ref")?.split(":").pop();

  const dispatch = useDispatch();
  const user = useSelector((state: IRootState) => state.user.user);

  const [customerQuery, setCustomerQuery] = useState("");

  useEffect(() => {
    for (const [key, value] of Array.from(params.entries())) {
      if (key.includes("customerId")) {
        if (!value) {
          const getCustomerValue = decodeURIComponent(key).split("=").pop();
          if (getCustomerValue) {
            setCustomerQuery(getCustomerValue);
          }
        } else {
          setCustomerQuery(value);
        }
      } else if (key.startsWith("ref")) {
        if (value) {
          setCustomerQuery(value.split(":").pop()!);
        } else {
          const getCustomerValue = decodeURIComponent(key).split("=").pop();
          if (getCustomerValue) {
            setCustomerQuery(getCustomerValue);
          }
        }
      }
    }
  }, [params]);
  // Extract customer based on query params or user.id from user state
  const { data, isLoading, error } = useGetCustomerQuery(customerQuery, {
    skip: customerQuery === "",
  });

  useEffect(() => {
    if (data) {
      dispatch(setUser(data));
    }

    if (error) {
      dispatch(setUser(null));
      appStorage.removeItem("user");
    }
  }, [data, error]);

  // Keep loading until some data arrived or there is a mismatch between user.id and customerId or extractCustomerId
  if (
    isLoading ||
    (user &&
      (customerQuery || extractCustomerId) &&
      user?.id !== customerQuery &&
      user?.id !== extractCustomerId)
  ) {
    return (
      <div className={styles.loadingScreen}>
        <CircularProgress color="#007EE5" spinnerSize="100px" stroke="8px" />

        <div>
          <h1>{t("common.loadingHeading")}</h1>
          <h2>{t("common.loadingSubheading")}</h2>
        </div>
      </div>
    );
  }

  if (!user && !data) {
    const router = createBrowserRouter([
      {
        path: "*",
        element: (
          <PageNotFound
            titleTranslationKey="title"
            messageTranslationKey="message"
          />
        ),
      },
    ]);

    return <RouterProvider router={router} />;
  }

  return children;
}
