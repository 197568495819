import { createSlice } from "@reduxjs/toolkit";
import { appStorage } from "lib/localStorage/LocalStorage";

export const themeSlice = createSlice({
  name: "theme",
  initialState: {
    theme: localStorage.getItem("theme") as "dark" | "light" || "",
  },
  reducers: {
    setTheme: (state, { payload: theme }) => {
      if (!theme) {
        return;
      }

      state.theme = theme;
      appStorage.setItem("theme", theme);
    },
  },
});

export const { setTheme } = themeSlice.actions;

export default themeSlice.reducer;
