import { RouterProvider, createBrowserRouter } from "react-router-dom";

import { LayoutApp } from "layouts/LayoutApp";
import { AuthProvider } from "providers/AuthProvider";

import { PageAllInvoices } from "pages/PageAllInvoices";
import { PageFAQ, PageFAQDetails } from "pages/PageFAQ";
import { PageHome } from "pages/PageHome";
import { PageInvoiceDetails } from "pages/PageInvoiceDetails";
import { PageNotifications } from "pages/PageNotifications";
import { PagePayment } from "pages/PagePayment";
import { PagePrivacy } from "pages/PagePrivacy";
import { PageProfile } from "pages/PageProfile";
import { PageTerms } from "pages/PageTerms";
import { PageInvoice } from "pages/PageInvoice/PageInvoice";
import { PageNotFound } from "pages/PageNotFound/PageNotFound";
import { ProtectedInvoice } from "pages/ProtectedInvoice/ProtectedInvoice";
import { LayoutNoBannerPage } from "layouts/LayoutApp/LayoutNoBannerPage";

const routes =  [
  {
    path: "/",
    element: <PageHome />,
    // TODO: What is the homepage?
  },
  {
    path: "/invoice/:id",
    element: <ProtectedInvoice><PageInvoice /></ProtectedInvoice>,
  },
  {
    path: "/invoice/:id/details",
    element: <ProtectedInvoice><PageInvoiceDetails /></ProtectedInvoice>,
  },
  {
    path: "/invoice/:id/payment",
    element: <LayoutNoBannerPage><ProtectedInvoice><PagePayment /></ProtectedInvoice></LayoutNoBannerPage>,
  },
  {
    path: "/all-invoices",
    element: <LayoutNoBannerPage><PageAllInvoices /></LayoutNoBannerPage>,
  },
  {
    path: "/profile",
    element: <LayoutNoBannerPage><PageProfile /></LayoutNoBannerPage>,
  },
  {
    path: "/notifications",
    element: <LayoutNoBannerPage><PageNotifications /></LayoutNoBannerPage>,
  },
  {
    path: "/privacy",
    element: <LayoutNoBannerPage><PagePrivacy /></LayoutNoBannerPage>,
  },
  {
    path: "/terms",
    element: <LayoutNoBannerPage><PageTerms /></LayoutNoBannerPage>,
  },
  {
    path: "/faq",
    element: <LayoutNoBannerPage><PageFAQ /></LayoutNoBannerPage>,
  },
  {
    path: "/faq/refunds",
    element: <LayoutNoBannerPage><PageFAQDetails translationKey="refunds" /></LayoutNoBannerPage>,
  },
  {
    path: "/faq/declined-payments",
    element: <LayoutNoBannerPage><PageFAQDetails translationKey="declinedPayments" /></LayoutNoBannerPage>,
  },
  {
    path: "/faq/frauds",
    element: <LayoutNoBannerPage><PageFAQDetails translationKey="frauds" /></LayoutNoBannerPage>,
  },
  {
    path: "/faq/account-privacy",
    element: <LayoutNoBannerPage><PageFAQDetails translationKey="accountPrivacy" /></LayoutNoBannerPage>,
  },
  {
    path: "*",
    element: <PageNotFound
      titleTranslationKey="title"
      messageTranslationKey="message"
    />
  }
];

const router = createBrowserRouter([
  {
    element: <LayoutApp />,
    children: routes
  },
]);

export default function App(): JSX.Element {
  return (
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  );
}
