import { useTranslation } from "react-i18next";

import { Button } from "components/Button";
import { Card } from "components/Card";
import { Icon } from "components/Icon";
import { Text } from "components/Text";

import { useHead } from "lib/hooks/useHead";

import styles from "./PagePayment.module.scss";
import { CircularProgress } from "components/Progress/CircularProgress";
import { SwishStatusResponse } from "lib/types";
import { SwishError } from "./SwishError";

type Props = {
  invoiceId: string;
  swishStatus?: SwishStatusResponse;
  isLoading?: boolean;
  alreadyPaid?: boolean;
};

export function PagePaymentStatus({ alreadyPaid = false, invoiceId, swishStatus, isLoading }: Props) {
  const { t } = useTranslation();
  useHead(t("pages.paymentStatus.confirmed"));

  // If for some reason an error occurs
  if(swishStatus && swishStatus.status === 500) {
    return <SwishError swishResponse={swishStatus} />;
  }

  // If it is awaiting payment
  if (!alreadyPaid && isLoading || (swishStatus && swishStatus.status === 202)) {
    return (
      <div className={styles.paymentConfirmedContainer}>
        <Card className={styles.paymentConfirmedCard}>
          <CircularProgress color="#007EE5" spinnerSize="100px" stroke="8px" />
        </Card>
      </div>
    );
  }

  // Then it is successfully paid
  return (
    <div className={styles.paymentConfirmedContainer}>
      <Card className={styles.paymentConfirmedCard}>
        <Icon name="tick" size={94} intent="--color-green" />

        <div>
          <Text as="h1" style="h2">
            {t("pages.paymentStatus.confirmed")}
          </Text>

          <Text as="p" style="body" color="--static-content-subtle">
            {t("pages.paymentStatus.messageConfirmed")}
          </Text>
        </div>

        <Button
          intent="secondary"
          text={t("common.continue")}
          onClick={() => {
            window.location.href = `/invoice/${invoiceId}`;
          }}
        />
      </Card>
    </div>
  );
}
