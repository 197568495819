import { useEffect } from "react";

/**
 * A custom React hook that detects and responds to clicks outside a specified element or elements.
 *
 * @param {React.RefObject<HTMLElement> | Array<React.RefObject<HTMLElement> | { key: string; element: React.RefObject<HTMLElement> }>} ref - A single React ref object or an array of React ref objects. Each ref represents an element to monitor for outside clicks.
 *   - If it's a single ref, the `callback` is invoked when a click occurs outside the element.
 *   - If it's an array, the array can contain:
 *     - React ref objects: The `callback` is invoked when a click occurs outside the corresponding element.
 *     - Objects with a `key` and an element ref: The `callback` is invoked with the `key` when a click occurs outside the corresponding element.
 * @param {(type?: string) => void} callback - A function to be called when an outside click is detected. Optionally, it can receive a `key` if provided by the `ref` array.
 * @returns {void}
 *
 * @example
 * // Example usage with a single ref
 * const ref = useRef<HTMLDivElement>(null);
 * useOutsideClick(ref, () => {
 *   console.log("Clicked outside the element!");
 * });
 *
 * @example
 * // Example usage with multiple refs
 * const refs = [
 *   useRef<HTMLDivElement>(null),
 *   { key: "menu", element: useRef<HTMLDivElement>(null) }
 * ];
 * useOutsideClick(
 *   refs,
 *   (type?: string) => {
 *     if (type === "hamburger") {
 *       setShowMenu(false);
 *     } else if (type === "language") {
 *       setShowLanguageSwitcher(false);
 *     }
 *   },
 * );
 */

export function useOutsideClick(
  ref:
    | React.RefObject<HTMLElement>
    | Array<
        | React.RefObject<HTMLElement>
        | {
            key: string;
            element: React.RefObject<HTMLElement>;
          }
      >,
  callback: (type?: string) => void,
): void {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!Array.isArray(ref)) {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          callback();
        }
      } else {
        ref.forEach((r) => {
          if ("current" in r) {
            if (r.current && !r.current.contains(event.target as Node)) {
              callback();
            }
          } else {
            if (
              r.element.current &&
              !r.element.current.contains(event.target as Node)
            ) {
              callback(r.key);
            }
          }
        });
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback]);
}
