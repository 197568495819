import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Button } from "components/Button";
import { Card } from "components/Card";
import { Icon } from "components/Icon";
import { Pill } from "components/Pill";
import { Text } from "components/Text";
import { Tooltip } from "components/Tooltip";

import { formatCurrencyWithNumberFormat } from "lib/helpers";
import {
  InvoicePaymentStatus,
  InvoiceStage,
  InvoiceStatus,
  PillIntent,
} from "lib/types";

import styles from "./InvoiceHeroDetails.module.scss";
import { pillIntentStageMapper, pillIntentStatusCallbackMapper } from "lib/mappers";

type Props = {
  invoiceId: string;
  displayId: string;
  amount: string;
  currency: string;
  dueDate: string;
  dateOfPayment?: string;
  language: string;
  invoicePaymentStatus: InvoicePaymentStatus;
  invoiceStatus: InvoiceStatus;
  invoiceStage: InvoiceStage;
  reminderFee?: string | null;
  remainingAmount?: string;
  paidAmount?: string;
};

export function InvoiceHeroDetails({
  invoiceId,
  displayId,
  amount,
  currency,
  dueDate,
  invoicePaymentStatus,
  invoiceStatus,
  invoiceStage,
  dateOfPayment,
  language,
  reminderFee,
  remainingAmount,
  paidAmount
}: Props) {
  const { t } = useTranslation();

  // Pill intent for stage and payment status
  // also mapped text for pill intents which will be localized
  const pillIntentForStage: PillIntent = pillIntentStageMapper[invoiceStage];
  let pillIntentForPaymentStatus: PillIntent = pillIntentStatusCallbackMapper(invoicePaymentStatus, invoiceStage);

  let pillIntentForPaymentStatusMappedText = t(
    `features.invoice.backendStatusTextTranslationMapping.${invoicePaymentStatus}`,
  );
  const pillIntentForStageMappedText = t(
    `features.invoice.backendStatusTextTranslationMapping.${invoiceStage}`,
  );

  // If invoice status is cancelled
  if (invoiceStatus === InvoiceStatus["cancelled"]) {
    pillIntentForPaymentStatus = "clear";
    pillIntentForPaymentStatusMappedText = t("features.invoice.backendStatusTextTranslationMapping.cancelled");
  }

  return (
    <Card>
      <>
        <div className={styles.header}>
          <Text as="p" style="body" strong>
            {t("features.invoice.details")}
          </Text>
          <Pill
            text={pillIntentForPaymentStatusMappedText}
            intent={pillIntentForPaymentStatus}
          />
        </div>
        <div className={styles.details}>
          {/* If payment status is not paid or overpaid */}
          
          <div>
            <Text as="p" style="body">
              {t("features.invoice.invoiceStage")}
            </Text>
            <Pill
              text={pillIntentForStageMappedText}
              intent={pillIntentForStage}
            />
          </div>
          

          <div className={styles.boldContent}>
            <Text as="p" style="body">
              {t("features.invoice.invoice")}
            </Text>
            <Text as="p" style="body">
              {formatCurrencyWithNumberFormat(amount, language, currency, false)}
            </Text>
          </div>

          {/* Show remaning amount if partially paid */}
          {invoicePaymentStatus === InvoicePaymentStatus["partiallyPaid"] && remainingAmount && (
            <>
              <div className={styles.boldContent}>
                <Text as="p" style="body">
                  {t("features.invoice.remainingAmount")}
                </Text>
                <Text as="p" style="body">
                  {formatCurrencyWithNumberFormat(
                    remainingAmount,
                    language,
                    currency,
                    false,
                  )}
                </Text>
              </div>
              <div>
                <Text as="p" style="body">
                  {t("features.invoice.paidTotal")}
                </Text>
                {paidAmount && (
                  <div className={styles.paidTotalTooltip}>
                    <Text as="p" style="body">
                      {formatCurrencyWithNumberFormat(
                        paidAmount,
                        language,
                        currency,
                        false,
                      )}
                    </Text>
                    <Tooltip text={t("features.invoice.paidTotalTooltip")}>
                      <Icon
                        name="infoCircle"
                        size={18}
                        intent="--static-content-emphasis"
                      />
                    </Tooltip>
                  </div>
                )}
              </div>
            </>
          )}

          {/* Due date */}
          <div className={styles.subtleColorContent}>
            <Text as="p" style="body">
              {t("features.invoice.expirationDate")}
            </Text>
            <Text as="p" style="body">
              {dueDate}
            </Text>
          </div>

          {/* Invoice id */}
          <div className={styles.subtleColorContent}>
            <Text as="p" style="body">
              {t("features.invoice.invoiceNumber")}
            </Text>
            <Text as="p" style="body">
              {displayId}
            </Text>
          </div>

          {/* Reminder fee */}
          {reminderFee && invoiceStage === InvoiceStage["reminded"] && (
            <div className={styles.subtleColorContent}>
              <Text as="p" style="body">
                {t("features.invoice.reminderFeeLabel")}
              </Text>
              <Text as="p" style="body">
                {formatCurrencyWithNumberFormat(
                  reminderFee,
                  language,
                  currency,
                  false,
                )}
              </Text>
            </div>
          )}

          {/* Date of payment */}
          {dateOfPayment && (
            <div>
              <Text as="p" style="body">
                {t("features.invoice.dateOfPayment")}
              </Text>
              <Text as="p" style="body">
                {dateOfPayment}
              </Text>
            </div>
          )}
        </div>

        {/* Pay button */}
        {invoiceStatus !== InvoiceStatus["cancelled"] &&
          invoicePaymentStatus !== InvoicePaymentStatus["paid"] &&
          invoicePaymentStatus !== InvoicePaymentStatus["overpaid"] &&
          invoiceStage !== InvoiceStage["debtCollection"] && (
          <div className={styles.btnContainer}>
            <Link to={`/invoice/${invoiceId}/payment`}>
              <Button
                text={t("features.invoice.pay", { amount: remainingAmount, currency })}
                intent="primary"
                icon="arrowRight"
                isStatic={false}
              />
            </Link>
          </div>
        )}

        {/* Intrum link */}
        {invoiceStatus !== InvoiceStatus["cancelled"] &&
          invoicePaymentStatus !== InvoicePaymentStatus["paid"] &&
          invoicePaymentStatus !== InvoicePaymentStatus["overpaid"] &&
          invoiceStage === InvoiceStage["debtCollection"] && (
          <div className={styles.btnContainer}>
            <Link to="https://intrum.se" target="_blank">
              <Button
                text={t("features.invoice.payIntrum")}
                intent="primary"
                icon="arrowUpRight"
                isStatic={false}
              />
            </Link>
          </div>
        )}
      </>
    </Card>
  );
}
