import { useTranslation } from "react-i18next";

import { PageHeader } from "components/PageHeader";
import { Text } from "components/Text";

export function PageTerms() {
  const { t } = useTranslation();

  return (
    <div className="content">
      <PageHeader title={t("pages.terms.title")} />

      <section style={{ marginTop: 16 }}>
        <Text as="p" style="body-small--reset-line-height">
          {t("pages.terms.content")}
        </Text>
      </section>
    </div>
  );
}
