import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Icon } from "components/Icon";
import { Notification } from "components/Notification";
import { Pill } from "components/Pill";
import { Text } from "components/Text";

import { convertKebabIntoCamelCased } from "lib/helpers";
import { InvoicePaymentStatus, InvoiceStage, PillIntent } from "lib/types";

import styles from "./InvoiceContainer.module.scss";

type Props = {
  paymentStatus: InvoicePaymentStatus;
  stage: InvoiceStage;
  invoiceId: string;
  displayId: string;
  date: string;
  amount: string;
  currency: string;
};

export function InvoiceContainer({
  paymentStatus,
  stage,
  invoiceId,
  displayId,
  date,
  amount,
  currency,
}: Props) {
  const { t } = useTranslation();

  const stageText = t(
    `features.invoice.backendStatusTextTranslationMapping.${convertKebabIntoCamelCased(stage)}`,
  );

  let intent: PillIntent | undefined = "info";

  if (stage === InvoiceStage["reminded"]) {
    intent = "notice";
  } else if (stage === InvoiceStage["debtCollection"]) {
    intent = "danger";
  }

  return (
    <Link to={`/invoice/${invoiceId}`} className={styles.invoiceContainer}>
      {stage && (
        <div className={styles.invoiceStage}>
          <Pill text={stageText} intent={intent} />
        </div>
      )}

      <div className={styles.invoiceContent}>
        <div className={styles.status}>
          <Notification
            intent={paymentStatus === InvoicePaymentStatus["paid"] ? "--color-green" : "--color-red"}
          />
          <Text as="p" style="body">
            {t("features.invoice.invoiceId", { id: displayId })}
          </Text>
        </div>

        <div className={styles.info}>
          <div>
            <Text as="p" style="body-small" className={styles.date}>
              {date}
            </Text>
            <div>
              <Text as="p" style="body">
                {amount} {currency}
              </Text>
            </div>
          </div>
          <Icon name="chevronRight" />
        </div>
      </div>
    </Link>
  );
}
