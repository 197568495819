import { countryCodeMapper } from "utils/i18n";
import { Contrast, Invoice } from "./types";

export function formatNumberToCurrency(amount: number): string {
  return amount.toLocaleString("sv-SE", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
}

export function formatCurrencyWithNumberFormat(
  amount: string | number,
  lng: string = "sv",
  currency?: string,
  lowercased?: boolean,
) {
  let cur;
  try {
    cur = new Intl.NumberFormat(
      `${countryCodeMapper[lng as keyof typeof countryCodeMapper]}`,
      {
        style: "currency",
        currency: currency,
      },
    ).format(Number(amount));
  } catch {
    cur = `${amount} ${currency}`;
  }

  if (lowercased) return cur?.toLowerCase();

  return cur;
}

export const copyToClipboard = (value: string) => {
  navigator.clipboard.writeText(value);
};

export function convertTimestampToDDMMYY(timestamp: string): string {
  const date = new Date(timestamp);

  // Pad single digit days and months with leading zeros
  const padWithZero = (num: number) => (num < 10 ? "0" + num : num.toString());

  const day = padWithZero(date.getUTCDate());
  const month = padWithZero(date.getUTCMonth() + 1); // Months are zero-based
  const year = date.getUTCFullYear();

  return `${day}-${month}-${year}`;
}

// Convert kebab cased words into camel cased
export function convertKebabIntoCamelCased(word: string) {
  return word.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
}

// Extract reminder fee
export function extractReminderFee(invoice: Invoice) {  
  return invoice.invoiceItems.find(
    el => el.name.toLowerCase() === "dunning fee"
  )?.totalAmount;
}

// Get contrast function
export const getContrastTextColor = (hex: string, returnBrightness = false) : number | Contrast | undefined => {
  let brightness;

  // Might not be needed but in case of back-end error we need to return some value
  // before trying to get length of hex argument | hex might be undefined due to server error
  if(!hex) {
    return Contrast["dark"];
  }

  if(hex.length === 7) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    // Calculate brightness (YIQ formula)
    brightness = (r * 299 + g * 587 + b * 114) / 1000;
  } else if(hex.length === 4) {
    const r = parseInt(hex[1] + hex[1], 16); // Duplicate single digit
    const g = parseInt(hex[2] + hex[2], 16); // Duplicate single digit
    const b = parseInt(hex[3] + hex[3], 16); // Duplicate single digit

    brightness = (r * 299 + g * 587 + b * 114) / 1000;
  }

  if(brightness || brightness === 0) {
    if(!returnBrightness) {
      return brightness > 128 ? Contrast["dark"] : Contrast["light"]; // Return black for light backgrounds, white for dark
    } else {
      return brightness;
    }
  }
};
