import { useCallback } from "react";
import styles from "./RadioButtons.module.scss";

type Props = {
  options: Array<{
    label: string;
    value: string;
    checked: boolean;
  }>;
  onChange: (value: string ) => void;
};

export function RadioButtons({ options, onChange }: Props) {
  /**
   * @description Add checked attribute to the target element
   * and remove the checked for the others
   */
  const addCheckedAttribute = useCallback((target: HTMLInputElement) => {
    target.parentElement?.parentElement?.querySelectorAll("label").forEach((label) => {
      if(label.querySelector("input")) {
        label.querySelector("input")!.checked = false;
        label.querySelector("input")!.removeAttribute("checked");
      }
    });

    target.checked = true;
    target.setAttribute("checked", "true");
  }, []);

  // Toggle checked state when focused on unchecked radio button
  const handleKeydown = useCallback((option: typeof options[number] ,e: React.KeyboardEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;

    // If enter was pressed
    if(e.key === "Enter") {
      addCheckedAttribute(target);
      onChange(option.value);
    }

    // If tab was pressed
    if(e.key === "Tab") {
      e.preventDefault();
      target.parentElement?.nextElementSibling?.querySelector("input")?.focus();
    }

    // If arrow up or arrow down was pressed
    if(e.key === "ArrowUp") {
      if(target.parentElement?.previousElementSibling?.querySelector("input")) {
        target.parentElement?.previousElementSibling?.querySelector("input")?.focus();
      }
    } else if(e.key === "ArrowDown") {
      if(target.parentElement?.nextElementSibling?.querySelector("input")) {
        target.parentElement?.nextElementSibling?.querySelector("input")?.focus();
      }
    }

  }, []);

  return (
    <div className={styles.radioButtonsWrapper}>
      {options.map((option, index) => {
        return (
          <label key={index} htmlFor={option.value}>
            <input
              tabIndex={1}
              type="radio"
              id={option.value}
              name="radio"
              value={option.value}
              checked={option.checked}
              onChange={() => onChange(option.value)}
              onKeyDown={handleKeydown.bind(null, option)}
            />
            {option.label}
          </label>
        );
      })}
    </div>
  );
}
