import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { PageHeader } from "components/PageHeader";
import { Tabs } from "components/Tabs";

import { useAppDispatch } from "lib/hooks/useAppDispatch";
import {
  BroadcastMapper,
  useBroadcastChannel,
} from "lib/hooks/useBroadcastChannel";
import { useHead } from "lib/hooks/useHead";
import { invoiceServiceSlice } from "lib/slices/invoiceServiceSlice";

import styles from "./PageAllInvoices.module.scss";
import { AllInvoicesTab } from "./tabs/AllInvoicesTab";
import { UnpaidInvoicesTab } from "./tabs/UnpaidInvoicesTab";
import { PaidInvoicesTab } from "./tabs/PaidInvoicesTab";

export function PageAllInvoices() {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(0);
  const dispatch = useAppDispatch();

  // This callback function will update 3 different cache entries in Redux
  // When the first tab is selected, it will filter out from all invoices, the invoice which id has came through the channel
  // When the second tab is selected, it will filter out from unpaid invoices, the invoice which id has came through the channel
  // When the third tab is selected, it will add to paid invoices cache, the invoice which has came through the channel
  const broadcastPostMessageHandler = useCallback(
    (eventData: BroadcastMapper["update_invoice"]["payload"]) => {
      if (selectedTab === 0) {
        dispatch(
          invoiceServiceSlice.util.updateQueryData(
            "getAllInvoices",
            "",
            (data) => {
              [...data.data].find((invoice) => {
                if (
                  eventData.invoice.paymentStatus &&
                  invoice.id === eventData.invoice.id
                ) {
                  invoice.paymentStatus = eventData.invoice.paymentStatus;
                }
              });
            },
          ),
        );
      }
      if (selectedTab === 1) {
        dispatch(
          invoiceServiceSlice.util.updateQueryData(
            "getUnpaidInvoices",
            "",
            (data) => {
              const filteredData = [...data.data].filter((invoice) => {
                if (invoice.id !== eventData.invoice.id) {
                  return true;
                }
              });

              return {
                data: filteredData,
                totalItems: data.totalItems - 1,
              };
            },
          ),
        );
      }

      if (selectedTab === 2) {
        dispatch(
          invoiceServiceSlice.util.updateQueryData(
            "getPaidInvoices",
            "",
            (data) => {
              return {
                data: eventData.invoice
                  ? ![...data.data].find(
                    (el) => el.id === eventData.invoice?.id,
                  )
                    ? [...data.data].concat(eventData.invoice)
                    : data.data
                  : data.data,
                totalItems: data.totalItems + 1,
              };
            },
          ),
        );
      }
    },
    [selectedTab, dispatch],
  );

  useBroadcastChannel("update_invoice", (eventData) =>
    broadcastPostMessageHandler(eventData),
  );

  const INDEX_TO_TITLE = [
    {
      title: t("pages.allInvoices.all"),
      tooltip: t("pages.allInvoices.tooltip.all"),
    },
    {
      title: t("pages.allInvoices.unpaid"),
      tooltip: t("pages.allInvoices.tooltip.unpaid"),
    },
    {
      title: t("pages.allInvoices.paid"),
      tooltip: t("pages.allInvoices.tooltip.paid"),
    },
  ];

  useHead(
    t("pages.allInvoices.title", {
      state: INDEX_TO_TITLE[selectedTab].title,
    }),
  );

  return (
    <div className={styles.content}>
      <PageHeader
        title={t("pages.allInvoices.title", {
          state: INDEX_TO_TITLE[selectedTab].title,
        })}
        // tooltip={INDEX_TO_TITLE[selectedTab].tooltip}
      />

      <section>
        <Tabs
          onChange={(index) => setSelectedTab(index)}
          tabs={[
            {
              tooltipText: INDEX_TO_TITLE[0].tooltip,
              title: t("pages.allInvoices.all"),
              content: (
                <AllInvoicesTab
                  key={selectedTab}
                  broadcastPostMessageHandler={broadcastPostMessageHandler}
                />
              ),
            },
            {
              tooltipText: INDEX_TO_TITLE[1].tooltip,
              title: t("pages.allInvoices.unpaid"),
              content: (
                <UnpaidInvoicesTab
                  key={selectedTab}
                  broadcastPostMessageHandler={broadcastPostMessageHandler}
                />
              ),
            },
            {
              tooltipText: INDEX_TO_TITLE[2].tooltip,
              title: t("pages.allInvoices.paid"),
              content: (
                <PaidInvoicesTab
                  key={selectedTab}
                  broadcastPostMessageHandler={broadcastPostMessageHandler}
                />
              ),
            },
          ]}
          hideWithCss={true}
        />
      </section>
    </div>
  );
}
