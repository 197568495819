import { RefObject, useEffect } from "react";

export function useScroll<Data, K extends HTMLElement>(
  ref: RefObject<K>,
  page: number,
  isFetching: boolean,
  data:
    | {
        data: Data;
        totalItems: number;
      }
    | undefined,
  cb: () => void,
) {
  useEffect(() => {
    const onScroll = () => {
      const scrolledToBottom =
        ref.current!.scrollHeight - ref.current!.scrollTop <=
        ref.current!.clientHeight + 100;

      if (
        data &&
        scrolledToBottom &&
        !isFetching &&
        (Array.isArray(data.data) ? data!.data.length < data!.totalItems : true)
      ) {
        cb();
      }
    };

    ref.current?.addEventListener("scroll", onScroll);

    return function () {
      ref.current?.removeEventListener("scroll", onScroll);
    };
  }, [page, isFetching, data]);
}
