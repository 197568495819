import { Button } from "components/Button";
import { Icon } from "components/Icon";
import { Text } from "components/Text";
import { Tooltip } from "components/Tooltip";

import { useGoBack } from "lib/hooks/useGoBack";

import styles from "./PageHeader.module.scss";

type Props = {
  title: string;
  tooltip?: string;
};

export function PageHeader({ title, tooltip }: Props) {
  const goBack = useGoBack();

  return (
    <header className={styles.header}>
      <Button intent="clear" icon="arrowLeft" onClick={goBack} />
      <div>
        <Text as="h4" style="h4" color="--static-content-emphasis">
          {title}
        </Text>
        {tooltip && (
          <Tooltip text={tooltip}>
            <Icon name="infoCircle" size={18} intent="--static-content-emphasis" />
          </Tooltip>
        )}
      </div>
    </header>
  );
}
