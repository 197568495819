import { TouchList, useEffect, useRef, useState } from "react";

import { Text } from "components/Text";

import { classNames } from "lib/classNames";
import { useOutsideClick } from "lib/hooks/useOutsideClick";

import styles from "./Tooltip.module.scss";

type Props = {
  text: string | JSX.Element;
  children: JSX.Element | JSX.Element[];
  showOnClick?: boolean;
  autoMinWidth?: boolean;
  position?: "left" | "right" | "top" | "bottom";
  intent?: "dark" | "light";
};

export function Tooltip({
  text,
  children,
  autoMinWidth,
  position,
  showOnClick = false,
  intent = "dark",
}: Props) {
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef<HTMLDivElement>(null);

  // Default tooltip to start from the left
  useEffect(() => {
    if (!tooltipRef.current) {
      return;
    }

    tooltipRef.current.style.left = "auto";
    tooltipRef.current.style.right = "0px";
  }, [tooltipRef]);

  // Hides tooltip on scroll (needed for mobile)
  useEffect(() => {
    const hideTooltip = () => {
      setShowTooltip(false);
    };

    window.addEventListener("scroll", hideTooltip);

    return () => {
      window.removeEventListener("scroll", hideTooltip);
    };
  }, []);

  useEffect(() => {
    let timer: number;

    if (showTooltip && showOnClick) {
      timer = window.setTimeout(() => {
        setShowTooltip((prev) => !prev);
      }, 3000);
    }

    return () => {
      if (showOnClick) {
        clearTimeout(timer);
      }
    };
  }, [showTooltip, showOnClick]);

  useOutsideClick(tooltipRef, () => {
    if (showOnClick) {
      setShowTooltip(false);
    }
  });

  const onMouseEnter = () => {
    setShowTooltip(true);

    if (!tooltipRef.current) {
      return;
    }
    // Checks if tooltip will overflow
    if (
      tooltipRef.current.getBoundingClientRect().left +
        tooltipRef.current.getBoundingClientRect().width >
      window.innerWidth
    ) {
      tooltipRef.current.style.left = "auto";
      tooltipRef.current.style.right = "0px";
    } else {
      tooltipRef.current.style.left = "var(--spacing-12)";
      tooltipRef.current.style.right = "auto";
    }
  };

  const onMouseLeave = () => {
    setShowTooltip(false);
  };

  const onTouch = (targetTouches: TouchList) => {
    if (!tooltipRef.current) {
      return;
    }

    setShowTooltip(!showTooltip);

    if (position !== "top") {
      tooltipRef.current.style.position = "fixed";
      tooltipRef.current.style.top = `${targetTouches[0].clientY + 12}px`;
      tooltipRef.current.style.width = "fit-content";
      tooltipRef.current.style.right = "8px";
    }
  };

  const tooltipTheme =
    `tooltip${(intent[0].toUpperCase() + intent.slice(1)) as Capitalize<NonNullable<Props["intent"]>>}` as const;

  return (
    <div className={styles.tooltipContainer}>
      <div
        onClick={() => (showOnClick ? onMouseEnter() : undefined)}
        onMouseEnter={() => (!showOnClick ? onMouseEnter() : undefined)}
        onTouchStart={(e) => onTouch(e.targetTouches)}
        onMouseLeave={() => (!showOnClick ? onMouseLeave() : undefined)}
      >
        {children}
      </div>
      <div
        ref={tooltipRef}
        className={classNames(styles.tooltip, styles[tooltipTheme], {
          [styles.tooltipShow]: showTooltip,
          [styles.tooltipTop]: position === "top",
          [styles.tooltipAutoMinWidth]: autoMinWidth === true,
        })}
      >
        {typeof text === "string" ? (
          <Text as="p" style="detail">
            {text}
          </Text>
        ) : (
          text
        )}
      </div>
    </div>
  );
}
