import { useTranslation } from "react-i18next";

import { SkeletonGroup } from "components/SkeletonGroup/SkeletonGroup";
import { Text } from "components/Text";

import useMediaQuery from "lib/hooks/useMediaQuery";
import { Appearance } from "lib/types";

import styles from "./Footer.module.scss";
import { FooterLink } from "./FooterLink";

export type FooterLink = {
  title: string;
  href: string;
  external?: boolean;
};

type Props = {
  logoSrc: string;
  companyName: string;
  appearance: Appearance;
  links: FooterLink[];
};

export function Footer({
  companyName,
  appearance = "dark",
  logoSrc,
  links,
}: Props) {
  const { t } = useTranslation();
  const isTablet = useMediaQuery("(min-width: 768px)");

  return (
    <footer
      className={styles.footer}
      data-appearance={isTablet ? "dark" : appearance}
    >
      <div className={styles.company}>
        {logoSrc ? (
          <span className={styles.logoContainer}>
            <img
              alt={t("common.logoOf", { company: companyName })}
              src={logoSrc}
            />
          </span>
        ) : (
          <SkeletonGroup
            options={[
              {
                width: "40px",
                height: "40px",
                borderRadius: "6px",
              },
            ]}
            wrapperStyles={{ padding: 0 }}
          />
        )}
        <Text as="p" style="h2">
          {companyName}
        </Text>
      </div>

      <div>
        <section className={styles.links}>
          {links.map((link, key) => (
            <FooterLink key={key} link={link} appearance={appearance} />
          ))}
        </section>
      </div>

      <Text as="p" style="body-small" className={styles.poweredBy}>
        <>
          {t("components.footer.poweredBy")}
          <strong>{t("components.footer.inio")}</strong>
        </>
      </Text>
    </footer>
  );
}
