import { Text } from "components/Text";

import { classNames } from "lib/classNames";

import styles from "./Pill.module.scss";
import { PillIntent } from "lib/types";

type Props = {
  text: string;
  intent?: PillIntent;
};

export function Pill({ text, intent = "clear" }: Props) {
  return (
    <div
      className={classNames(styles.pill, {
        [styles.pillSuccess]: intent === "success",
        [styles.pillError]: intent === "error",
        [styles.pillNotice]: intent === "notice",
        [styles.pillInfo]: intent === "info",
        [styles.pillDanger]: intent === "danger",
        [styles.pillWarning]: intent === "warning",
      })}
    >
      <Text as="p" style="detail" color={intent}>
        {text}
      </Text>
    </div>
  );
}
