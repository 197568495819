import { SkeletonGroup } from "components/SkeletonGroup/SkeletonGroup";
import { useGetInvoiceQuery } from "lib/slices/invoiceServiceSlice";
import { PageNotFound } from "pages/PageNotFound/PageNotFound";
import { useParams } from "react-router-dom";
import styles from "../PageHome/PageHome.module.scss";

export function ProtectedInvoice({
  children
}: { children: React.ReactNode }) {
  const params = useParams();

  const { isLoading, error } = useGetInvoiceQuery(params.id as string);

  if(isLoading) {
    return (
      <div className={styles.content}>
        <SkeletonGroup
          options={[
            {
              width: "100%",
              height: "300px",
              borderRadius: "16px",
              marginBottom: "16px",
            },
          ]}
        />
      </div>
    );
  }

  if(error) {
    return <PageNotFound
      titleTranslationKey="title"
      messageTranslationKey="message"
    />;
  }

  return children;
}