import { Icon } from "components/Icon";
import { Text } from "components/Text";

import styles from "./CardInfo.module.scss";
import { PillIntent } from "lib/types";
import { classNames } from "lib/classNames";

type Props = {
  text: string;
  intent?: Extract<PillIntent, "warning" | "danger">,
  withWarning?: boolean;
  customClasses?: Array<string>
};

export function CardInfo({ text, withWarning, intent, customClasses = [] }: Props) {
  return (
    <div className={classNames(styles.cardInfo, ...customClasses, {
      [styles.warning]: intent === "warning",
      [styles.danger]: intent === "danger",
    })}>
      {withWarning && (
        <Icon name="alertTriangle" intent="--color-red" size={20} />
      )}
      <Text as="p" style="body-small">
        {text}
      </Text>
    </div>
  );
}
