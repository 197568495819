import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";


import { Button } from "components/Button";
import { ErrorMessage } from "components/ErrorMessage/ErrorMessage";

import { SwishStatusResponse } from "lib/types";

export function SwishError({
  swishResponse,
  reason,
}: {
  swishResponse: SwishStatusResponse;
  reason?: "declined";
}) {
  const { t } = useTranslation();
  const params = useParams();

  return (
    <ErrorMessage>
      <h2>
        {swishResponse.error ||
          (reason === "declined" &&
            t("pages.paymentStatus.cancelledPayment")) ||
          t("pages.paymentStatus.swishErrorOccured")}
      </h2>
      <Button
        text={t("pages.paymentStatus.initNewPayment")}
        intent="primary"
        icon="arrowRight"
        onClick={() => {
          window.location.href = `/invoice/${params.id}/payment`;
        }}
        style={{ marginTop: "16px" }}
        isStatic={false}
      />
    </ErrorMessage>
  );
}
