import { useCallback, useEffect } from "react";
import { Invoice } from "lib/types";

export type BroadcastMapper = {
  update_invoice: {
    id: "update_invoice";
    payload: {
      invoice: Invoice;
    };
  };
};

/**
 * Custom hook to listen for messages on a BroadcastChannel.
 *
 * @template T - The key of the `BroadcastMapper` type.
 * @param {T} channelName - The name of the BroadcastChannel.
 * @param {(data: BroadcastMapper[T]["payload"]) => void} cb - Callback function to handle received messages.
 *
 * @example
 * useBroadcastChannel("update_invoice", (data) => {
 *   console.log("Received data:", data);
 * });
 * @example
 * If you want to use the additionalData with some state in Redux cache, you can use similar code
 * const cache = useSelector((state: IRootState) => {
 *   return invoiceServiceSlice.endpoints.getAllInvoices.select(0)(state)?.data;
 * });
 * 
 * if(cache && "data" in cache) {
 *   postMessage({
 *     id: id,
 *     status: "paid",
 *     additionalData: cache.data.find((invoice) => invoice.id === id)!
 *   });
 * }
 */
export function useBroadcastChannel<T extends keyof BroadcastMapper>(
  channelName: T,
  cb: (data: BroadcastMapper[T]["payload"]) => void,
) {
  useEffect(() => {
    const channel = new BroadcastChannel(channelName);

    channel.onmessage = (event) => {
      cb(event.data);
    };

    return () => {
      channel.close();
    };
  }, [cb]);
}

/**
 * Custom hook to send messages on a BroadcastChannel.
 *
 * @template T - The key of the `BroadcastMapper` type.
 * @param {T} channelName - The name of the BroadcastChannel.
 * @returns {(payload: BroadcastMapper[T]["payload"]) => void} - Function to send messages on the channel.
 *
 * @example
 * const postMessage = usePostMessage("update_invoice");
 * postMessage({ id: "123", status: "paid" });
 */
export function usePostMessage<T extends keyof BroadcastMapper>(
  channelName: T,
) {
  const postMessage = useCallback(
    (payload: BroadcastMapper[T]["payload"]) => {
      const channel = new BroadcastChannel(channelName);

      channel.postMessage(payload);

      channel.close();
    },
    [channelName],
  );

  return postMessage;
}
