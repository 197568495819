import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, ScrollRestoration } from "react-router-dom";

import { Footer } from "components/Footer";
import { Navbar } from "components/Navbar";
import { SkeletonGroup } from "components/SkeletonGroup/SkeletonGroup";

import { setLanguage } from "lib/slices/languageSlice";
import { setTheme } from "lib/slices/themeSlice";

import i18n from "utils/i18n";

import styles from "./LayoutApp.module.scss";
import { classNames } from "lib/classNames";
import { IRootState } from "lib/store";
import { Contrast } from "lib/types";
import { getContrastTextColor } from "lib/helpers";

const SHOW_BANNER = [/^\/$/, /^\/invoice\/[a-f0-9-]+$/, /^\/invoice\/[a-f0-9-]+\/details$/];

export function LayoutApp() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useSelector<IRootState, "dark" | "light">((state: IRootState) => state.theme.theme);
  const user = useSelector((state: IRootState) => state.user.user);
  const defaultBannerPath = useRef("/assets/default/default-banner.webp");

  const [clientInfo, setClientInfo] = useState({
    name: "",
    logoSrc: "",
    bannerSrc: "",
    links: {
      website: {
        title: t("components.footer.website"),
        href: "#",
        external: true,
      },
      support: {
        title: t("components.footer.support"),
        href: "/support",
      },
      account: {
        title: t("components.footer.account"),
        href: "/account",
      },
    },
  });

  const [clientData, setClientData] = useState<{
    loading: boolean;
    error: string | null;
  }>({
    loading: false,
    error: null,
  });

  // Set default logos based on theme
  const setDefaultLogo = useCallback((contrast: ReturnType<typeof getContrastTextColor>) => {
    if(contrast === Contrast["dark"]) {
      return "/assets/default/default-logo-dark.svg";
    } else {
      return "/assets/default/default-logo-light.svg";
    }
  }, []);

  useEffect(() => {
    setClientData({
      loading: true,
      error: null,
    });

    if (user) {
      fetch(
        process.env.REACT_APP_API_CLIENTS_URL! + `/clients/${user.clientId}`,
      )
        .then((res) => {
          if (!res.ok) {
            setClientData({
              loading: false,
              error: res.statusText,
            });
          }

          return res.json();
        })
        .then((data) => {          
          if (data.data) {
            // Get contrast
            const contrast = getContrastTextColor(data.data["brandColor"]);

            // Set clients info
            setClientInfo((prev) => ({
              ...prev,
              links: {
                ...prev.links,
                website: {
                  ...prev.links["website"],
                  href: data.data["website"]
                    ? data.data["website"]
                    : "https://www.inio.com/en",
                },
              },
              bannerSrc:
                data.data["banner_uri"] ||
                defaultBannerPath.current,
              logoSrc:
                data.data["logo_uri"] || setDefaultLogo(contrast),
              name: data.data["name"],
            }));

            // Set primary color
            document.documentElement.style.setProperty(
              "--primary-color",
              data.data["brandColor"]
            );

            // Set theme
            dispatch(setTheme(contrast !== Contrast["dark"] ? "dark" : "light"));

            // Get brightness
            const getBrightness = getContrastTextColor(data.data["brandColor"], true);

            // Set stroke variable for very whitesh/grayish colors
            if(typeof getBrightness === "number") {
              document.documentElement.style.setProperty(
                "--stroke-inverted",
                getBrightness > 230 ? Contrast["dark"] : data.data["brandColor"]
              );
            }

            // Set global variables
            if(contrast === Contrast["dark"] || contrast === Contrast["light"]) {
              document.documentElement.style.setProperty(
                "--content-emphasis",
                contrast !== Contrast["dark"] ? "rgb(0, 0, 0)" : "rgb(255, 255, 255)"
              );
              document.documentElement.style.setProperty(
                "--content-subtle",
                contrast !== Contrast["dark"] ? "rgba(0, 0, 0, 0.502)" : "rgba(255, 255, 255, 0.502)"
              );
              document.documentElement.style.setProperty(
                "--content-muted",
                contrast !== Contrast["dark"] ? "rgba(0, 0, 0, 0.302)" : "rgba(255, 255, 255, 0.302)"
              );
              document.documentElement.style.setProperty(
                "--content-emphasis-inverted",
                contrast !== Contrast["dark"] ? "rgb(255, 255, 255)" : "rgb(0, 0, 0)"
              );
              document.documentElement.style.setProperty(
                "--content-subtle-inverted",
                contrast !== Contrast["dark"] ? "rgba(255, 255, 255, 0.502)" : "rgba(0, 0, 0, 0.502)"
              );
              document.documentElement.style.setProperty(
                "--content-muted-inverted",
                contrast !== Contrast["dark"] ? "rgba(255, 255, 255, 0.302)" : "rgba(0, 0, 0, 0.302)"
              );
            }
          }

          setClientData({
            loading: false,
            error: null,
          });

          document
            .querySelector("link[rel='icon']")
            ?.setAttribute("href", data.data["logo_uri"] || "/assets/default/default-logo-light.svg");
          document
            .querySelector("link[rel='ap']")
            ?.setAttribute("href", data.data["logo_uri"] || "/assets/default/default-logo-light.svg");
        });
    } else {
      setClientInfo((prev) => ({
        ...prev,
        bannerSrc: "/assets/default/default-banner.webp",
        logoSrc: "/assets/default/default-logo-light.svg",
        name: "wave",
      }));

      setClientData({
        loading: false,
        error: null,
      });

      document
        .querySelector("link[rel='icon']")
        ?.setAttribute("href", "/assets/default/default-logo-light.svg");
      document
        .querySelector("link[rel='ap']")
        ?.setAttribute("href", "/assets/default/default-logo-light.svg");
    }
  }, [user]);

  const doesRouteHaveBanner = useCallback(() => {
    for(const regexp of SHOW_BANNER) {
      if(location.pathname === "/" || regexp.test(location.pathname)) {
        return true;
      }
    }
    return false;
  }, [location]);

  // This is here to update the html lang attribute on first load
  useEffect(() => {
    dispatch(setLanguage(i18n.language));
  }, []);

  return (
    <>
      {clientData.loading ? (
        <SkeletonGroup
          options={[
            {
              width: "100%",
              height: "40vh",
              borderRadius: "15px",
            },
          ]}
        />
      ) : (
        <>
          <Navbar
            companyName={clientInfo.name}
            logoSrc={clientInfo.logoSrc}
            bannerSrc={clientInfo.bannerSrc}
            doesRouteHasBanner={doesRouteHaveBanner()}
          />
          <main className={classNames(styles.main, {
            [styles.noTranslate]: !doesRouteHaveBanner()
          })}>
            <Outlet />
          </main>
        </>
      )}

      <Footer
        companyName={clientInfo.name}
        logoSrc={clientInfo.logoSrc}
        appearance={theme}
        links={Object.values(clientInfo.links)}
      />
      <ScrollRestoration />
    </>
  );
}
