import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { IsEmail, RInioInput, RInioInputRadio, RInioInputTel } from "ui-core";

import { Card } from "components/Card";
import { PageHeader } from "components/PageHeader";

import {
  useGetCustomerQuery,
  useUpdateCustomerMutation,
} from "lib/slices/customerServiceSlice";
import { setLanguage } from "lib/slices/languageSlice";
import { IRootState } from "lib/store";
import { User } from "lib/types";

import styles from "./PageProfile.module.scss";

export function PageProfile() {
  const { t } = useTranslation();
  const user = useSelector((state: IRootState) => state.user.user);
  const { data: userDetails, refetch } = useGetCustomerQuery(
    user?.id as string,
  );
  const currentLanguage = useSelector(
    (state: IRootState) => state.language.language,
  );

  const dispatch = useDispatch();

  const handleChangeLanguage = (
    e: React.ChangeEvent<HTMLInputElement> & { detail?: { value: string } },
  ) => {
    if (currentLanguage !== e.detail?.value) {
      dispatch(setLanguage(e.detail?.value));
    }
  };

  const [updateCustomer] = useUpdateCustomerMutation();

  const [formErrors, setFormErrors] = useState<{
    name: string;
    email: string;
    phone: string;
  }>({
    name: "",
    email: "",
    phone: "",
  });

  const handleUpdateUser = async (name: string, event: CustomEvent) => {
    if (!userDetails) {
      return;
    }

    // Amend the object to match the endpoint expected payload
    const existingUser: Partial<User> = { ...userDetails };
    delete existingUser.id;
    delete existingUser.createdAt;
    delete existingUser.updatedAt;
    delete existingUser.deletedAt;

    const updatedUser = {
      id: userDetails.id,
      payload: {
        ...existingUser,
        [name]: event.detail.value,
      },
    };

    updateCustomer(updatedUser)
      .unwrap()
      .then(() => refetch())
      .catch((error) => {
        setFormErrors((prev) => ({
          ...prev,
          [name]: error,
        }));
      });
  };

  return (
    <div className="content">
      <PageHeader title={t("pages.profile.title")} />

      <Card className={styles.card}>
        <RInioInput
          label={t("pages.profile.name")}
          readOnly
          editable
          value={user?.name}
          required
          onSave={handleUpdateUser.bind(null, "name")}
        />
        {
          (formErrors.name && (
            <p className={styles.errorMsg}>{formErrors.name}</p>
          )) as ReactElement
        }

        <RInioInput
          label={t("pages.profile.email")}
          readOnly
          editable
          value={user?.email}
          required
          validators={[
            new IsEmail(undefined, {
              getMessage: () => t("pages.profile.invalidEmail"),
            }),
          ]}
          onSave={handleUpdateUser.bind(null, "email")}
        />
        {
          (formErrors.email && (
            <p className={styles.errorMsg}>{formErrors.email}</p>
          )) as ReactElement
        }

        <RInioInputTel
          label={t("pages.profile.phoneNumber")}
          preferredRegions={["SE", "NO", "FI", "BG"]}
          editable
          defaultValue={user?.phone}
          required
          readOnly
          onSave={handleUpdateUser.bind(null, "phone")}
        />
        {
          (formErrors.phone && (
            <p className={styles.errorMsg}>{formErrors.phone}</p>
          )) as ReactElement
        }
      </Card>

      <Card className={styles.card}>
        <>
          <div>
            <RInioInputRadio
              name="language"
              label={t("pages.profile.language")}
              onChange={handleChangeLanguage}
              defaultValue={currentLanguage}
              options={[
                {
                  label: t("pages.profile.english"),
                  value: "en",
                },
                {
                  label: t("pages.profile.swedish"),
                  value: "sv",
                },
              ]}
            />
          </div>
        </>
      </Card>
    </div>
  );
}
