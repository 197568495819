import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";

import { InvoicePaymentCard } from "features/invoice";

import { PageHeader } from "components/PageHeader";

import { usePostMessage } from "lib/hooks/useBroadcastChannel";
import { useHead } from "lib/hooks/useHead";
import { useGetInvoiceQuery } from "lib/slices/invoiceServiceSlice";
import {
  useGetSwishStatusQuery,
  useInitSwishPaymentMutation,
} from "lib/slices/paymentServiceSlice";
import {
  InvoicePaymentStatus,
  PaymentProviders,
  SwishPaymentRequest,
} from "lib/types";

import styles from "./PagePayment.module.scss";
import { PagePaymentStatus } from "./PagePaymentStatus";
import { SwishError } from "./SwishError";

const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export function PagePayment() {
  const { t } = useTranslation();
  useHead(t("pages.payment.title"));
  const params = useParams();
  const [searchParams] = useSearchParams();

  const [stopPollingInvoice, setStopPollingInvoice] = useState(false);

  const swishIdRef = searchParams.get("ref"); // comes from Swish callback
  const mockIdRef = searchParams.get("mockRef"); // comes from Swish callback
  const [mockPaid, setMockPaid] = useState(false);

  const { data: invoice } = useGetInvoiceQuery(params.id as string, {
    pollingInterval: 5000,
    skip: stopPollingInvoice,
  });

  const [initSwishPayment, res] = useInitSwishPaymentMutation({
    fixedCacheKey: "swish-payment",
  });

  const swishId =
    res.data?.id ?? searchParams.get("ref")?.split(":").pop() ?? "";

  const { data: getSwishStatus, isLoading } = useGetSwishStatusQuery(
    {
      swishId,
      invoiceId: invoice?.id ?? "",
    },
    {
      pollingInterval: 3000,
      skip:
        swishId === "" ||
        !invoice ||
        invoice.paymentStatus === InvoicePaymentStatus["paid"],
    },
  );

  const amount = invoice
    ? invoice.remainingAmount && invoice.remainingAmount > 0
      ? invoice.remainingAmount
      : Number(invoice.totalAmount)
    : 0;

  const onSwishClick = () => {
    if (!invoice) {
      console.error("No invoice found!");
      return;
    }

    const payload: SwishPaymentRequest = {
      invoiceId: params.id as string,
      payeePaymentReference: invoice.paymentReference as string,
      payeeAlias: "1233908639",
      amount,
      currency: "SEK",
      callbackUrl: "https://localhost:3000/2/payment", // not used by backend but needed
      deviceType: isMobile ? "mobile" : "desktop",
      message: "test mctest, why is this message needed?",
    };

    initSwishPayment(payload);
  };

  const postMessage = usePostMessage("update_invoice");

  useEffect(() => {
    if (invoice?.paymentStatus === InvoicePaymentStatus["paid"]) {
      setStopPollingInvoice(true);

      postMessage({
        invoice,
      });
    }

    if (searchParams.has("ref")) {
      searchParams.delete("ref");
    }
  }, [invoice]);

  useEffect(() => {
    if (res.data?.status === 201) {
      setStopPollingInvoice(true);
    }
  }, [res]);

  // If a declined/cancelled payment occurs
  if (getSwishStatus?.status === 500) {
    return (
      <SwishError
        swishResponse={getSwishStatus}
        reason={getSwishStatus.reason}
      />
    );
  }

  // TODO: Add loading state
  if (!invoice) {
    return <></>;
  }

  if (mockPaid || invoice?.paymentStatus === InvoicePaymentStatus["paid"]) {
    return (
      <PagePaymentStatus
        invoiceId={invoice.id}
        swishStatus={getSwishStatus}
        isLoading={isLoading}
      />
    );
  }

  if (mockIdRef) {
    sleep(2500).then(() => setMockPaid(true));
    return (
      <PagePaymentStatus
        invoiceId={invoice.id}
        swishStatus={getSwishStatus}
        isLoading={isLoading}
      />
    );
  }

  if (swishIdRef) {
    return (
      <PagePaymentStatus
        invoiceId={invoice.id}
        isLoading={searchParams.has("ref")}
      />
    );
  }

  const providers: Array<PaymentProviders> = [
    {
      type: "bank-transfer",
    },
  ];

  if (invoice.currency.toLowerCase() === "sek") {
    providers.push({
      type: "swish",
      onOpenCallback: onSwishClick,
    });
  }

  return (
    <div className={styles.content}>
      <PageHeader title={t("pages.payment.title")} />

      <div className={styles.paymentOptions}>
        <InvoicePaymentCard
          price={amount}
          currency={invoice?.currency}
          providers={providers}
          invoice={invoice}
        />
      </div>
    </div>
  );
}
