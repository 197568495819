import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Button } from "components/Button";
import { Text } from "components/Text";

import { classNames } from "lib/classNames";
import { useOutsideClick } from "lib/hooks/useOutsideClick";

import { LanguageSwitcher } from "./LanguageSwitcher";
import { Menu } from "./Menu";
import styles from "./Navbar.module.scss";

type Props = {
  companyName: string;
  logoSrc: string;
  bannerSrc: string;
  doesRouteHasBanner: boolean;
};

export const SHOW_FULL_LOGO = [
  "allente",
  "dentolo",
  "unicef",
  "redcross",
  "epark",
];

export function Navbar({ companyName, logoSrc, bannerSrc, doesRouteHasBanner }: Props) {
  const { t } = useTranslation();
  const [showMenu, setShowMenu] = useState(false);
  const [showLanguageSwitcher, setShowLanguageSwitcher] = useState(false);

  // Refs
  const hamburgerContainer = useRef(null);
  const languageSwitcherRef = useRef(null);


  // Handle outside click to hide the menu and language switcher dropdowns
  useOutsideClick(
    [
      {
        key: "hamburger",
        element: hamburgerContainer,
      },
      {
        key: "language",
        element: languageSwitcherRef,
      },
    ],
    (type?: string) => {
      if (type === "hamburger") {
        setShowMenu(false);
      } else if (type === "language") {
        setShowLanguageSwitcher(false);
      }
    },
  );

  const navigate = useNavigate();
  const handleClickOnLogo = () => {
    navigate("/");
  };

  return (
    <>
      <nav className={styles.navbar}>
        <div
          className={styles.leftContainer}
          onClick={handleClickOnLogo}
          role="button"
          tabIndex={0}
          aria-label={t("accessibilityText.navigateToHomePage")}
        >
          <span className={styles.logoContainer}>
            <img
              alt={t("common.logoOf", { company: companyName })}
              src={logoSrc}
            />
          </span>

          <Text as="p" style="h2">
            {companyName}
          </Text>
        </div>

        <div className={styles.dropdowns}>
          <LanguageSwitcher
            isOpen={showLanguageSwitcher}
            onClick={() => setShowLanguageSwitcher(!showLanguageSwitcher)}
            ref={languageSwitcherRef}
          />

          <span className={styles.menuContainer} ref={hamburgerContainer}>
            <Button intent="clear" onClick={() => setShowMenu(!showMenu)}>
              <i
                className={classNames(styles.hamburger, {
                  [styles.active]: showMenu,
                })}
              />
            </Button>

            <Menu isOpen={showMenu} onClick={() => setShowMenu(false)} />
          </span>
        </div>
      </nav>

      <div
        className={styles.headerImage}
        style={{
          display: doesRouteHasBanner ? "block" : "none",
        }}
      >
        <picture>
          <source
            type="image/webp"
            srcSet={bannerSrc}
            media="(min-width: 1025px)"
          />
          <source type="image/webp" srcSet={bannerSrc} />
          <img src={bannerSrc} alt="" />
        </picture>
      </div>
    </>
  );
}
